import { useState, useEffect } from 'react'
import { Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { RouterLink } from '../components/RouterLink'
import { http, to } from '../helpers'

export const CustomerListRoute = () => {
  const [status, setStatus] = useState('loading')
  const [data, setData] = useState({ byId: {}, ids: [] })

  useEffect(() => {
    http.get('v1.Customer.List').then(data => {
      setData({
        byId: data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
        ids: data.map(cur => cur.id),
      })
      setStatus('resolved')
    })
  }, [])

  if ('loading' === status) {
    return 'Loading...'
  }

  return (
    <>
      <Box mb={3}>
        <RouterLink to="/Customer.Create">Add client</RouterLink>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Pumps</TableCell>
              <TableCell>Cl. Mileage</TableCell>
              <TableCell>Last Order</TableCell>
              <TableCell>Last Mileage</TableCell>
              <TableCell>Last Order Date</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.ids.map(id => {
              const cur = data.byId[id]

              return (
                <TableRow key={id}>
                  <TableCell>
                    <RouterLink to={`/Customer.Edit?customer=${id}`}>{cur.name}</RouterLink>
                  </TableCell>
                  <TableCell>
                    <RouterLink to={`/Device.List?customer=${id}`}>{cur.devices}</RouterLink>
                  </TableCell>
                  <TableCell>{to.Litre(cur.totalVolume)}</TableCell>
                  <TableCell>
                    {null == cur.order?.amount ? '—' : to.Litre(cur.order.amount)}
                  </TableCell>
                  <TableCell>
                    {null == cur.order ? '—' : to.Litre(cur.volume)}
                  </TableCell>
                  <TableCell>
                    {null == cur.order?.createdAt ? '—': to.DateTime(cur.order.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    <RouterLink to={`/Order.Create?customer=${id}`}>Order</RouterLink>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}