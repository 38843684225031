import { useNavigate } from 'react-router-dom'
import { http } from '../helpers'
import { BatchFormComponent } from './BatchForm.component'

const defaultValues = {
  uid: null,
  multiplier: 1,
  inertia: 0,
}

export const BatchCreateRoute = () => {
  const navigate = useNavigate()

  return (
    <BatchFormComponent
      title="Add lot"
      defaultValues={defaultValues}
      onCommit={handleCommit}
      onCancel={() => navigate('/Batch.List')}
    />
  )

  function handleCommit(formValues) {
    return http.post('v1.Batch.Create', formValues).then(res => {
      if (res.ok) {
        navigate('/Batch.List')
      }
    })
  }
}