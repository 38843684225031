import { TextField, Autocomplete } from '@mui/material'

export const AutocompleteTextField = props => {
  const { label, options, getOptionLabel, onChange, ...AutocompleteProps } = props

  return (
    <Autocomplete
      {...AutocompleteProps}
      options={options.ids}
      getOptionLabel={id => getOptionLabel(options.byId[id])}
      renderInput={TextFieldProps=> <TextField {...TextFieldProps} label={label} />}
      onChange={(e, nextValue) => onChange(nextValue)}
    />
  )
}
