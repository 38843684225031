import { BatchListRoute } from './BatchList.route'
import { BatchCreateRoute } from './BatchCreate.route'
import { BatchEditRoute } from './BatchEdit.route'

const routes = [
  {
    path: '/Batch.List',
    element: <BatchListRoute/>,
  },
  {
    path: '/Batch.Create',
    element: <BatchCreateRoute/>,
  },
  {
    path: '/Batch.Edit',
    element: <BatchEditRoute/>,
  },
]

export default routes