import { DeviceListRoute } from './DeviceList.route'
import { DeviceCreateRoute } from './DeviceCreate.route'
import { DeviceEditRoute } from './DeviceEdit.route'

const routes = [
  {
    path: '/Device.List',
    element: <DeviceListRoute/>,
  },
  {
    path: '/Device.Create',
    element: <DeviceCreateRoute/>,
  },
  {
    path: '/Device.Edit',
    element: <DeviceEditRoute/>,
  },
]

export default routes