import { CustomerListRoute } from './CustomerList.route'
import { CustomerCreateRoute } from './CustomerCreate.route'
import { CustomerEditRoute } from './CustomerEdit.route'

const routes = [
  {
    path: '/Customer.List',
    element: <CustomerListRoute/>,
  },
  {
    path: '/Customer.Create',
    element: <CustomerCreateRoute/>,
  },
  {
    path: '/Customer.Edit',
    element: <CustomerEditRoute/>,
  }
]

export default routes