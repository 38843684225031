import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, CardHeader, CardContent, CardActions, Divider, Stack, TextField, Button } from '@mui/material'
import { AutocompleteTextField } from '../components/AutocompleteTextField'
import { http, produce, F } from '../helpers'

export const DeviceEditRoute = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [options, setOptions] = useState({})
  const [status, setStatus] = useState('loading')
  const [defaultValues, setDefaultValues] = useState({})
  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    const r1 = http.post('v1.Device.Get', {
      device: params.get('device')
    }).then(res => {
      const initialValues = {
        multiplier: res.multiplier,
        customer: res.customer?.id || null,
      }
      setDefaultValues(initialValues)
      setFormValues(initialValues)
    })
    const r2 = http.get('v1.Customer.List').then(res => {
      setOptions({
        ids: res.map(cur => cur.id),
        byId: res.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      })
    })

    Promise.all([r1, r2]).then(() => {
      setStatus('resolved')
    }).catch(err => {
      console.error(err)
    })
  }, [params])

  if ('loading' === status) {
    return 'Loading...'
  }

  return (
    <Card>
      <CardHeader title="Edit pump" />
      <Divider/>
      <CardContent>
        <Stack spacing={3}>
          <AutocompleteTextField
            label="Customer"
            options={options}
            defaultValue={defaultValues.customer}
            getOptionLabel={o => o.name}
            onChange={nextValue => handleChange('customer', nextValue)}
          />
          <TextField
            label="K"
            type="text"
            defaultValue={F.Number.input(defaultValues.multiplier)}
            onChange={e => handleChange('multiplier', F.Number.output(e.target.value))}
          />
        </Stack>
      </CardContent>
      <Divider/>
      <CardActions>
        <Button
          variant="contained"
          disabled={'pending' === status}
          onClick={handleCommit}
          children="Save"
        />
        <Button
          onClick={() => redirect()}
          children="Cancel"
        />
      </CardActions>
    </Card>
  )

  function handleChange(name, value) {
    setFormValues(prev => produce(prev, name, value))
  }

  function handleCommit() {
    setStatus('pending')

    http.post('v1.Device.Update', {
      ...formValues,
      customer: formValues.customer || '',
      device: params.get('device'),
    }).then(res => {
      if (res.ok) {
        redirect()
      }
    })
  }

  function redirect() {
    return params.has('customer')
      ? navigate(`/Device.List?customer=${params.get('customer')}`)
      : navigate('/Device.List')
  }
}