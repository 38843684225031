import customer from './Customer/routes'
import batch from './Batch/routes'
import device from './Device/routes'
import order from './Order/routes'

const routes = [
  ...batch,
  ...customer,
  ...device,
  ...order,
]

export default routes