import { useNavigate } from 'react-router-dom'
import { http } from '../helpers'
import { CustomerForm } from './CustomerForm'

const defaultValues = {
  name: null,
}

export const CustomerCreateRoute = () => {
  const navigate = useNavigate()

  return (
    <CustomerForm
      title="Add client"
      defaultValues={defaultValues}
      onCommit={handleCommit}
      onClose={() => navigate('/Customer.List')}
    />
  )

  function handleCommit(formValues) {
    return http.post('v1.Customer.Create', formValues).then(res => {
      if (res.ok) {
        navigate('/Customer.List')
      }
    })
  }
}