import { useState, useEffect } from 'react'
import { Card, CardHeader, CardContent, CardActions, Divider, Stack, TextField, Button } from '@mui/material'
import { AutocompleteTextField } from '../components/AutocompleteTextField'
import { http, produce, F } from '../helpers'

const toOptions = ary => {
  return {
    ids: ary.map(cur => cur.id),
    byId: ary.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
  }
}

export const DeviceFormComponent = props => {
  const { title, defaultValues, onCommit, onCancel } = props
  const [options, setOptions] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)

  useEffect(() => {
    Promise.all([
      http.get('v1.Batch.List'),
      http.get('v1.Customer.List')
    ]).then(res => {
      setOptions({
        batch: toOptions(res[0]),
        customer: toOptions(res[1]),
      })
      setLoading(false)
    })
  }, [])

  if (isLoading) {
    return 'Loading...'
  }

  return (
    <Card>
      <CardHeader title={title} />
      <Divider/>
      <CardContent>
        <Stack spacing={3}>
          <TextField
            label="Number"
            type="text"
            defaultValue={F.String.input(defaultValues.uid)}
            onChange={e => handleChange('uid', F.String.output(e.target.value))}
          />
          <AutocompleteTextField
            label="Client"
            defaultValue={defaultValues.customer}
            options={options.customer}
            getOptionLabel={o => o.name}
            onChange={nextValue => handleChange('customer', nextValue)}
          />
          <AutocompleteTextField
            label="Lot"
            defaultValue={defaultValues.batch}
            options={options.batch}
            getOptionLabel={o => o.uid}
            onChange={nextValue => handleChange('batch', nextValue)}
          />
        </Stack>
      </CardContent>
      <Divider/>
      <CardActions>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleCommit}
          children="Save"
        />
        <Button onClick={onCancel}>Cancel</Button>
      </CardActions>
    </Card>
  )

  function handleChange(name, value) {
    setFormValues(prev => produce(prev, name, value))
  }

  function handleCommit() {
    setSubmitting(true)
    onCommit(formValues).catch(err => {
      console.error(err)
    })
  }
}