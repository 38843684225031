import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { http } from '../helpers'
import { DeviceFormComponent } from './DeviceForm.component'

export const DeviceCreateRoute = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const defaultValues = useMemo(() => {
    return {
      uid: null,
      customer: params.get('customer'),
      batch: null,
    }
  }, [params])
  return (
    <DeviceFormComponent
      title="Add pump"
      defaultValues={defaultValues}
      onCommit={handleCommit}
      onCancel={() => redirect(params.get('customer'))}
    />
  )

  function handleCommit(formValues) {
    return http.post('v1.Device.Create', formValues).then(res => {
      if (res.ok) {
        redirect(formValues.customer)
      }
    })
  }

  function redirect(customer) {
    return customer
      ? navigate(`/Device.List?customer=${customer}`)
      : navigate('/Device.List')
  }
}