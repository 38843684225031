import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Card, CardHeader, CardContent, CardActions, Divider, Stack, TextField, Button } from '@mui/material'
import { http, produce, F } from '../helpers'

const defaultValues = {
  amount: null,
}

export const OrderCreateRoute = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)

  return (
    <Card>
      <CardHeader title="New order" />
      <Divider/>
      <CardContent>
        <Stack spacing={3}>
          <TextField
            label="Order amount"
            type="number"
            defaultValue={F.Number.input(defaultValues.amount)}
            onChange={e => handleChange('amount', F.Number.output(e.target.value))}
          />
        </Stack>
      </CardContent>
      <Divider/>
      <CardActions>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleCommit}
          children="Save"
        />
        <Button onClick={handleCancel}>Cancel</Button>
      </CardActions>
    </Card>
  )

  function handleChange(name, value) {
    setFormValues(prev => produce(prev, name, value))
  }

  function handleCommit() {
    const body = {
      ...formValues,
      amount: null === formValues.amount ? 0 : formValues.amount * 1000,
      customer: params.get('customer'),
    }

    setSubmitting(true)

    return http.post('v1.Order.Create', body).then(res => {
      if (res.ok) {
        navigate(`/Customer.List`)
      }
    })
  }

  function handleCancel() {
    navigate(`/Customer.List`)
  }
}