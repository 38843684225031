import { useState } from 'react'
import { Card, CardHeader, CardContent, CardActions, Divider, Stack, TextField, Button } from '@mui/material'
import { produce, F } from '../helpers'

export const CustomerForm = props => {
  const { title, defaultValues, onCommit, onCancel } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)

  return (
    <Card>
      <CardHeader title={title} />
      <Divider/>
      <CardContent>
        <Stack spacing={3}>
          <TextField
            label="Client name"
            defaultValue={F.String.input(defaultValues.name)}
            onChange={e => handleChange('name', F.String.output(e.target.value))}
          />
        </Stack>
      </CardContent>
      <Divider/>
      <CardActions>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleCommit}
          children="Save"
        />
        <Button onClick={onCancel}>Cancel</Button>
      </CardActions>
    </Card>
  )

  function handleChange(name, value) {
    setFormValues(prev => produce(prev, name, value))
  }

  function handleCommit() {
    setSubmitting(true)

    onCommit(formValues).catch(err => {
      console.error(err)
      setSubmitting(false)
    })
  }
}