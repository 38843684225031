import { Outlet } from 'react-router-dom'
import { Container, Box } from '@mui/material'
import { RouterLink } from '../components/RouterLink'

export const RootRoute = () => {
  return (
    <Container maxWidth="xl">
      <Box py={2}>
        <RouterLink to="/Customer.List">Clients</RouterLink>
        {' | '}
        <RouterLink to="/Device.List">Pumps</RouterLink>
        {' | '}
        <RouterLink to="/Batch.List">Lots</RouterLink>
      </Box>
      <Outlet/>
    </Container>
  )
}