const set = (object, path, value) => {
  let index = -1
  const tempPath = /^\w*$/.test(path) ? [path] : path.split('.')
  const length = tempPath.length
  const lastIndex = length - 1

  while (++index < length) {
    const key = tempPath[index];
    let newValue = value;

    if (index !== lastIndex) {
      const objValue = object[key];

      if (typeof objValue === 'object') {
        newValue = { ...objValue }
      } else if (Array.isArray(objValue)) {
        newValue = objValue.slice()
      } else if (!isNaN(+tempPath[index + 1])) {
        newValue = []
      } else {
        newValue = {}
      }
    }
    object[key] = newValue;
    object = object[key];
  }

  return object
}

export const produce = (prev, path, value) => {
  const next = { ...prev }
  set(next, path, value)
  return next
}

export const extend = (a, b) => {
  const keys = Object.keys(a)
  const dest = {}

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]

    if (b.hasOwnProperty(key)) {
      if (!!b[key] && typeof b[key] === 'object') {
        dest[key] = extend(a[key], b[key])
      } else {
        dest[key] = b[key]
      }
    } else {
      dest[key] = a[key]
    }
  }

  return dest
}

export const F = {
  String: {
    input: value => null == value ? '' : String(value),
    output: value => '' === value ? null : value,
  },
  Number: {
    input: value => null == value ? '' : String(value),
    output: value => '' === value ? null : Number(value),
  },
  Array: {
    input: value => Array.isArray(value) ? value : [],
    output: value => Array.isArray(value) ? value : [],
  },
  Boolean: {
    input: value => Boolean(value),
    output: value => Boolean(value),
  },
  Raw: {
    input: value => value,
    output: value => value,
  },
}

const API_ROOT = process.env.REACT_APP_API_ROOT || 'http://127.0.0.1:8000'

export const http = {
  get: path => {
    return fetch(`${API_ROOT}/${path}`).then(res => res.json())
  },
  post: (path, body) => {
    const formData = Object.keys(body).reduce((acc, key) => {
      acc.set(key, body[key])
      return acc
    }, new FormData())

    return fetch(`${API_ROOT}/${path}`, {
      method: 'POST',
      body: formData,
    }).then(res => {
      return res.json()
    })
  },
}

export const toLitre = m => (m / 1000).toFixed(3)

export const to = {
  Litre: toLitre,
  DateTime: raw => {
    const pad = x => Math.abs(x).toString().padStart(2, '0')
    const dt = new Date(raw)
    const d1 = [pad(dt.getUTCDate()), pad(dt.getUTCMonth() + 1), dt.getFullYear()].join('-')
    const d2 = [pad(dt.getUTCHours()), pad(dt.getUTCMinutes()), pad(dt.getUTCSeconds())].join(':')

    return [d1, d2].join(' ')
  },
}
