import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { http } from '../helpers'
import { BatchFormComponent } from './BatchForm.component'

export const BatchEditRoute = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState('loading')
  const [defaultValues, setDefaultValues] = useState({})
  const [params] = useSearchParams()

  useEffect(() => {
    http.post('v1.Batch.Get', {
      batch: params.get('batch')
    }).then(res => {
      setDefaultValues({
        uid: res.uid,
        inertia: res.inertia,
        multiplier: res.multiplier,
      })
      setStatus('resolved')
    })
  }, [params])

  if ('loading' === status) {
    return 'Loading...'
  }

  return (
    <BatchFormComponent
      title="Edit lot"
      defaultValues={defaultValues}
      onCommit={handleCommit}
      onCancel={() => navigate('/Batch.List')}
    />
  )

  function handleCommit(formValues) {
    return http.post('v1.Batch.Edit', {
      ...formValues,
      batch: params.get('batch'),
    }).then(res => {
      if (res.ok) {
        navigate('/Batch.List')
      }
    })
  }
}