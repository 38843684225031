import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { http } from '../helpers'
import { CustomerForm } from './CustomerForm'

export const CustomerEditRoute = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [status, setStatus] = useState('loading')
  const [defaultValues, setDefaultValues] = useState({})

  useEffect(() => {
    http.post('v1.Customer.Get', {
      customer: params.get('customer')
    }).then(res => {
      setDefaultValues({
        name: res.name,
      })
      setStatus('resolved')
    }).catch(err => {
      console.error(err)
    })
  }, [params])

  if ('loading' === status) {
    return 'Loading...'
  }

  return (
    <CustomerForm
      title="Edit client"
      defaultValues={defaultValues}
      onCommit={handleCommit}
      onCancel={() => navigate('/Customer.List')}
    />
  )

  function handleCommit(formValues) {
    return http.post('v1.Customer.Update', {
      ...formValues,
      customer: params.get('customer'),
    }).then(res => {
      if (res.ok) {
        navigate('/Customer.List')
      }
    })
  }
}