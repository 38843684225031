import { useState, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Link, Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { RouterLink } from '../components/RouterLink'
import { http, to } from '../helpers'

const FILTER_ACTIVE = 0
const FILTER_UNASSIGNED = 1

export const DeviceListRoute = () => {
  const [params] = useSearchParams()
  const [status, setStatus] = useState('loading')
  const [data, setData] = useState([])
  const [filter, setFilter] = useState(FILTER_ACTIVE)
  const query = useMemo(() => {
    const body = {}

    if (params.has('customer')) {
      body['customer'] = params.get('customer')
    } else if (FILTER_UNASSIGNED === filter) {
      body['customer'] = ''
    }

    return body
  }, [filter, params])

  useEffect(() => {
    http.post('v1.Device.List', query).then(res => {
      if (res.ok) {
        setData(res.data)
        setStatus('resolved')
      }
    }).catch(err => {
      console.error(err)
    })
  }, [query])

  if ('loading' === status) {
    return 'Loading...'
  }

  return (
    <>
      {!params.has('customer') && <Filter value={filter} onChange={handleFilterChange}/>}
      <CreateDeviceLink customer={params.get('customer')} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pump</TableCell>
              <TableCell>Lot</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Mileage</TableCell>
              <TableCell>Last order</TableCell>
              <TableCell>Last Mileage</TableCell>
              <TableCell>Order past</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>K_lot</TableCell>
              <TableCell>K_pump</TableCell>
              <TableCell>I_lot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(cur => {
              return (
                <TableRow key={cur.id}>
                  <TableCell>
                    {params.has('customer') ? (
                      <RouterLink to={`/Device.Edit?device=${cur.id}&customer=${params.get('customer')}`}>
                        {cur.id}
                      </RouterLink>
                    ) : (
                      <RouterLink to={`/Device.Edit?device=${cur.id}`}>{cur.id}</RouterLink>
                    )}
                    <div>{cur.uid}</div>
                  </TableCell>
                  <TableCell>{cur.batch.uid}</TableCell>
                  <TableCell>{cur.customer?.name || '—'}</TableCell>
                  <TableCell>{to.Litre(cur.totalVolume)}</TableCell>
                  <TableCell>
                    {null == cur.customer?.order?.amount ? '—' : to.Litre(cur.customer.order.amount)}
                  </TableCell>
                  <TableCell>
                    {null == cur.customer?.order ? '—' : to.Litre(cur.volume)}
                  </TableCell>
                  <TableCell>
                    {null == cur.customer?.order?.createdAt ? '—' : to.DateTime(cur.customer.order.createdAt)}
                  </TableCell>
                  <TableCell>
                    {null == cur.updatedAt ? '—': to.DateTime(cur.updatedAt)}
                  </TableCell>
                  <TableCell>{cur.batch.multiplier}</TableCell>
                  <TableCell>{cur.multiplier}</TableCell>
                  <TableCell>{cur.batch.inertia}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )

  function handleFilterChange(nextValue) {
    setFilter(nextValue)
  }
}

function CreateDeviceLink(props) {
  const { customer } = props

  return (
    <Box mb={3}>
      {customer ? (
        <RouterLink to={`/Device.Create?customer=${customer}`}>Add pump</RouterLink>
      ) : (
        <RouterLink to="/Device.Create">Add pump</RouterLink>
      )}
    </Box>
  )
}

function Filter(props) {
  const { value, onChange } = props

  return (
    <Box mb={2}>
      <TabLink
        disabled={FILTER_ACTIVE === value}
        onClick={() => onChange(FILTER_ACTIVE)}
        children="Active"
      />
      {' | '}
      <TabLink
        disabled={FILTER_UNASSIGNED === value}
        onClick={() => onChange(FILTER_UNASSIGNED)}
        children="Unassigned"
      />
    </Box>
  )
}

function TabLink(props) {
  const { disabled, ...rest } = props

  return (
    <Link
      {...rest}
      underline={disabled ? 'none' : 'always'}
      component="button"
      variant="body1"
    />
  )
}