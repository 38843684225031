import { useState, useEffect } from 'react'
import { Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { RouterLink } from '../components/RouterLink'
import { http } from '../helpers'

export const BatchListRoute = () => {
  const [status, setStatus] = useState('loading')
  const [data, setData] = useState({ byId: {}, ids: [] })

  useEffect(() => {
    http.get('v1.Batch.List').then(data => {
      setData({
        byId: data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
        ids: data.map(cur => cur.id),
      })
      setStatus('resolved')
    })
  }, [])

  if ('loading' === status) {
    return 'Loading...'
  }

  return (
    <>
      <Box mb={3}>
        <RouterLink to="/Batch.Create">Add lot</RouterLink>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>K</TableCell>
              <TableCell>I</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.ids.map(id => {
              const cur = data.byId[id]

              return (
                <TableRow key={id}>
                  <TableCell>
                    <RouterLink to={`/Batch.Edit?batch=${cur.id}`}>{cur.uid}</RouterLink>
                  </TableCell>
                  <TableCell>{cur.multiplier}</TableCell>
                  <TableCell>{cur.inertia}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}