import { useState } from 'react'
import { Card, CardHeader, CardContent, CardActions, Divider, Stack, TextField, Button } from '@mui/material'
import { produce, F } from '../helpers'

export const BatchFormComponent = props => {
  const { title, defaultValues, onCommit, onCancel } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [formValues, setFormValues] = useState(defaultValues)

  return (
    <Card>
      <CardHeader title={title} />
      <Divider/>
      <CardContent>
        <Stack spacing={3}>
          <TextField
            label="Number"
            type="text"
            defaultValue={F.String.input(defaultValues.uid)}
            onChange={e => handleChange('uid', F.String.output(e.target.value))}
          />
          <TextField
            label="K"
            type="number"
            defaultValue={F.Number.input(defaultValues.multiplier)}
            onChange={e => handleChange('multiplier', F.Number.output(e.target.value))}
          />
          <TextField
            label="I"
            type="number"
            defaultValue={F.Number.input(defaultValues.inertia)}
            onChange={e => handleChange('inertia', F.Number.output(e.target.value))}
          />
        </Stack>
      </CardContent>
      <Divider/>
      <CardActions>
        <Button
          variant="contained"
          disabled={isSubmitting}
          onClick={handleCommit}
          children="Save"
        />
        <Button onClick={onCancel}>Cancel</Button>
      </CardActions>
    </Card>
  )

  function handleChange(name, value) {
    setFormValues(prev => produce(prev, name, value))
  }

  function handleCommit() {
    setSubmitting(true)
    onCommit(formValues).catch(err => {
      console.error(err)
    })
  }
}